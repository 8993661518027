import $ from "jquery";


$(document).ready(() => {

    $("#geom-selector").on("click",function (e) {
        e.preventDefault();
        $(".geom-wpml-menu").removeClass("geom-wpml-menu--hide");
        $(".geom-wpml-menu").addClass("geom-wpml-menu--show");


        $("body").on('click', function (e) {
            if ($(e.target).closest('#geom-selector').length === 0) {
                $(".geom-wpml-menu").addClass("geom-wpml-menu--hide");
                $(".geom-wpml-menu").removeClass("geom-wpml-menu--show");
            }
        });




    })

});

